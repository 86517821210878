body {
  display: block;
  background: #20232A;
  margin: 0;
  padding: 0;
}

strong {
  white-space: pre;
}

.root_menu {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  position: absolute;
  right: 0;
}

.App {
  text-align: left;
  width: 300px;
  padding: 0;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  right: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-y: overlay;
  overflow-x: hidden;
}

.App h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 48px;
  display: flex;
  align-items: left;
  color: #FFFFFF;
  margin: 0 0 27px 0;
  white-space: pre;
}

.App h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: left;
  color: #FFFFFF;
  margin: 0 0 -27px 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.show {
  /* display: block; */
  /* transition: all 550ms linear; */
}

.hide {
  /* display: none;
  transition: all 550ms linear; */
}


.auth_block {
  /* transition: all 5s linear; */
  display: flex;
  margin: 50px 0 20px;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  transition: margin-top .5s ease-in-out;
}

.auth_block li {
  display: block;
  transition: transform .5s ease-in-out;
}

.auth_block li:nth-child(1).show {
  transform: translate3d(0px, 0px, 0px);
}

.auth_block li:nth-child(2).show {
  transform: translate3d(-300px, 0px, 0px);
}

.auth_block li:nth-child(3).show {
  transform: translate3d(-600px, 0px, 0px);
}

.auth_block li:nth-child(1).hide {
  transform: translate3d(300px, 0px, 0px);
}

.auth_block li:nth-child(2).hide {
  transform: translate3d(0px, 0px, 0px);
}

.auth_block li:nth-child(3).hide {
  transform: translate3d(-300px, 0px, 0px);
}

.auth_block li.show {
  transition-delay: .5s;
  /* animation: show 5s ease-in-out; */
}

.auth_block li.hide {
  transition-delay: 0.25s;
  pointer-events: none;
  /* display: none; */
}

.auth_block form div {
  transition-delay: 0.2s;
  transition: transform .5s ease-in-out;
}

.auth_block .show form div {
  transform: translate3d(0px, 0px, 0px);
}

.auth_block .hide form div {
  transform: translate3d(300px, 0px, 0px);
}

.auth_block .hide .btn_left button {
  transition: transform .3s ease-in-out;
  transform: translate3d(300px, 0px, 0px);
  transition-delay: 0.05s;
}

.auth_block .hide .btn_left button:nth-child(2) {
  transition-delay: 0s;
}

.auth_block form div:nth-child(2) {
  transition-delay: 0.15s;
}

.auth_block form div:nth-child(3) {
  transition-delay: 0.1s;
}

.auth_block form div:nth-child(4) {
  transition-delay: 0.05s;
}

.auth_block form div:nth-child(5) {
  transition-delay: 0s;
}


nav {
  display: block;
  transition: all 1s linear;
  max-height: 100vh;
}

form {
  max-width: 300px;
  width: 300px;
  margin: 0 auto;
}

.i_button {
  cursor: pointer;
  font-style: normal;
  white-space: pre;
}

.i_button:hover {
  color: #DB1C5D;
}

.input-wrap {
  height: 44px;
  position: relative;
  margin-bottom: 40px;
  transition: all .25s linear;
}

.btn-wrap {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin: 5px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.input-wrap input {
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  background: none;
  padding: 0;
  line-height: 43px;
  border: 0;
  text-align: left;
  display: block;
  color: #fff;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #CF1C5D;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #FFFFFF !important;
  color: rgb(0, 3, 185) !important;
  -webkit-box-shadow: 0 0 0 1000px #202429 inset !important;
  -webkit-text-fill-color: #cf1c5d !important;
}

.input-wrap input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #202429 !important;
  background-image: none !important;
  color: #FFFFFF !important;
  color: -internal-light-dark(white, white) !important;
}

.input-wrap input:focus {
  box-shadow: 0 2px 0px 0px #cf1c5d;
}

.input-wrap input::placeholder {
  color: rgba(255, 255, 255, 0);
}


.input-wrap .placeholder {
  transition: all .25s linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  text-transform: inherit;
  color: #FFFFFF;
  outline: none;
  font-size: 22px;
  word-spacing: 18px;
  font-variant-numeric: tabular-nums;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.input-wrap input:focus+label {
  font-weight: 600;
}

.input-wrap .placeholder {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.input-wrap input:focus+.placeholder, .input-wrap input:not(:placeholder-shown)+.placeholder, .model-window .input-wrap input:focus+.placeholder, .model-window .input-wrap input:not(:placeholder-shown)+.placeholder {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  /* color: #2b3942; */
  top: -15px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-transform: inherit;

  color: #FFFFFF;

  opacity: 0.5;
}




.btn-wrap .btn {
  background: rgba(0, 0, 0, 0);
  transition: all 0.25s linear;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  line-height: 55px;
  height: 55px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

/* .btn-wrap .btn:after {
  content: "";
  width: 55px;
  height: 55px;
  position: absolute;
  background-image: url(./images/arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 42px;
  top: 0;
  right: 0;
  transition: all 0.25s linear;
  transform: translateX(0px);
}
.btn-wrap .btn:hover:after {
  transform: translateX(4px);
} */
.btn-wrap .btn svg {
  transition: all 0.25s linear;
  transform: translateX(0px);
  width: 45px;
  height: 45px;
  margin: 5px;
  display: inline;
  fill: none;
  stroke: #DA1C5C;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
  image-rendering: optimizeSpeed
}

.btn-wrap .btn.prew {
  transform: scale(-1, 1);
}

.btn-wrap .btn.next {
  padding-right: 5px;
}

.btn.prew:active,
.btn.next:active {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
}

.btn-wrap .btn:hover svg {
  transform: translateX(4px);
  /* stroke-width:0.8; */
}

.btn-wrap .btn:hover path {
  stroke-dasharray: 25;
  stroke-dashoffset: 0;
  animation: dash 1.5s;
}

.btn-wrap .btn.submitted path {
  stroke-dasharray: 25;
  stroke-dashoffset: 0;
  animation: dash 1s infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dashoffset: 25;
  }

  100% {
    stroke-dashoffset: 50;
  }
}

*:focus {
  outline: none;
}

button, input, optgroup, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  -webkit-appearance: none;
}

*::selection {
  background: #c4e7ff;
}

.btn_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin-top: -65px;
  height: 76px;
  width: 180px;
  transform: translate3d(0px, 0px, 0px);
}

.btn_left .btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  background: none;
  border: 0;
  margin: 10px 0;
  cursor: pointer;
}

.btn_left .btn:hover {
  color: #DB1C5D;
}

ul,
li {
  padding: 0;
  display: block;
  /* margin: 12px 0; */
  margin: 1vh 0;
}

.page_navigations li {
  transition: all .5s;
}

.page_navigations {
  width: 100%;
}

.page_navigations a {
  width: 300px;
  height: 54px;
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #DB1C5D;
  text-decoration: none;
}

.page_navigations a::before {
  /* content:attr(data-num);
  position: absolute;
margin: 0;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
letter-spacing: 1.5px;
font-weight: 400;
transform: translate3d(-3px, -3px, 0);
overflow: hidden;
max-width: 0px;
transition: max-width .5s; */
}

.page_navigations a::after {
  content: attr(data-text);
  position: absolute;
  width: 300px;
  height: 54px;
  margin: 0;
  left: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  overflow: hidden;
  max-width: 0px;
  transition: max-width 1s;
}

.page_navigations li:hover a::before,
.page_navigations li:hover a::after {
  max-width: 400px;
}

.page_navigations li:hover {
  transform: translateX(5px);
}

#path24 {
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  animation: dash2 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  display: inline;
  fill: none;
  stroke: #ff8600;
  stroke-width: 0.6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-opacity: 0.59919;
}

@keyframes dash2 {
  0% {
    stroke-dashoffset: -25;
  }

  50% {
    stroke-dashoffset: 0;
  }

  95% {
    stroke-dashoffset: 25;
  }

  100% {
    stroke-dashoffset: 25;
  }
}


#dashboard {
  position: absolute;
  background: #20232a;
  color: #fff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 5s;
  /* transform: translate3d(200px, 10px, 10px); */
  transform: translate3d(0px, 0px, 0px);
  padding-top: 60px;

}

.root_menu #dashboard {
  transform: translate3d(0px, 0px, 0px);
}

#dashboard .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#dashboard h1 {
  margin: 50px 10px 30px;
  text-align: center;
}

.works-tabs-title {
  margin: 0 0px 28px;
}

#dashboard .works-tabs {
  max-width: 1000px;
  width: 100%;
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.works-tabs-box__item{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.works-tabs-title__item {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  /* -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%; */
  line-height: 1;
  display: flex;
  padding: 0 0px 8px;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  margin: 14px;
  transition: all 0.25s linear;
  transform: translateY(0px);
}

.works-section .row img {
  max-width: 80px;
}

.works-tabs-title__item img {
  margin-right: 6px;
}

.works-tabs-title__item.active:after {
  opacity: 1;
}

.works-tabs-title__item::after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #da1c5b;
  opacity: 0;
  transition: opacity .25s linear;
}

#dashboard .link {
  border-radius: 14px;
  -webkit-align-self: center;
  align-self: center;
  cursor: pointer;
  height: 48px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 0 22px;
  line-height: 44px;
  display: inline-block;
  position: relative;
  top: auto;
  left: auto;
  transition: all .25s linear;
  border: 2px solid #da1c5b;
  padding: 0 75px 0 54px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.25s linear;
  transform: translateY(0px);
}
.works-tabs-title__item:hover,
.table .col-btn .btn:hover,
#dashboard .link:hover{
  transform: translateY(-3px);
}

#dashboard .link::before {
  content: "";
  position: absolute;
  right: 14px;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  width: 25px;
  height: 30px;
  background-image: url(./images/arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

#dashboard .link:after {
  content: "";
  width: 50px;
  top: 0px;
  height: 100%;
  /* background: #00ebfd; */
  position: absolute;
  right: 0px;
}

.add-form-license {
  display: none;
  max-width: 400px;
  margin: 70px auto 40px;
}

.add-form-license .item-input {
  height: 44px;
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid #2b3942;
}

.add-form-license .item-input label {
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 43px;
  font-size: 18px;
  color: #2b3942;
  font-weight: 400;
}

.add-form-license .item-input input {
  background: none;
  padding: 0;
  line-height: 43px;
  border: 0;
  text-align: center;
  display: block;
  color: #2b3942;
  width: 100%;
  margin: 0;
}
.license_form{
  transition: all .25s linear;
  position: relative;
  overflow: hidden;
  height: 0px;
  opacity: 0;
}
.license_form.open{
  height: 180px;
  opacity: 1;
}




.table {
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 17px 0px rgb(0 0 0 / 30%);
  box-shadow: 0 6px 17px 0px rgb(0 0 0 / 30%);
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 5px 10px 18px;
  margin-bottom: 20px;
  background: #2B3036;
}

.table-row {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table .col {
  display: table-row;
}

.table .col b, .table .col p {
  font-weight: 400;
  padding: 10px 0;
  display: table-cell;
  line-height: 1.2;
  font-size: 16px;
}

.table .col-btn {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  -webkit-align-self: center;
  align-self: center;
}

.table .col-btn .btn {
  border-radius: 14px;
  cursor: pointer;
  height: 48px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 44px;
  display: inline-block;
  position: relative;
  transition: all .25s linear;
  border: 2px solid #da1c5b;
  padding: 0 25px;
}
.input_mask{
}
.license_form .input-wrap input {
color: #FFFFFF;
letter-spacing: 8px;
word-spacing: 8px;
font-variant-numeric: tabular-nums;
}
.input_mask i{
  position: relative;
  background: none;
  padding: 0;
  line-height: 43px;
  border: 0;
  text-align: left;
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  /* color: #fff; */
  letter-spacing: 8px;
  word-spacing: 8px;
  font-variant-numeric: tabular-nums;
  color: transparent;
}
.input_mask i.spot{
  color: transparent;
}
.input_mask i.spot::after{
  content: "•";
  color: #fff;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  text-align: left;
  font-size: 30px;
}

@media (min-width: 820px){
.table-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
}
.table .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


}