/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2b313500 #da1c5b;
}

*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-thumb {
  background-color: #da1c5b;
  border-radius: 20px;
  border: 1px solid #9e1f62;
}
*::-webkit-scrollbar-track {
  background: #2b313500;
}


.root_menu {
  scrollbar-width: thin;
  scrollbar-color: #2b313500 #da1c5b00;
}
.root_menu::-webkit-scrollbar-thumb {
  background-color: #da1c5b00;
  border-radius: 20px;
  border: 1px solid #9e1f6300;
}
.root_menu::-webkit-scrollbar-track {
  background: #2b313500;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*{
-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
-webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  position: relative;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background: linear-gradient(to right, #20232a 0%, #20232a 50%, #2b3036 50%, #2b3036 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20232a', endColorstr='#2b3036', GradientType=1 );
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}
  .main{ 
  transform: scale(1) translate3d(0px, 0, 0);
  transition: transform .7s;
  border-radius: 0px;
  box-shadow: 1px 1px 18px -2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: linear-gradient(to right, #20232a 0%, #20232a 50%, #2b3036 50%, #2b3036 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20232a', endColorstr='#2b3036', GradientType=1 );
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  margin: 0 auto;

  
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    transition: transform .4s ease-in-out;

}
.mouse_wheel_block{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 1;
}
.main_header{    
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.main:after {
  content: "";
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: #20232a;
}
.main.menu_active{
  transform: scale(.8) translate3d(-400px, 0, 0);
  border-radius: 42px;
  box-shadow: 1px 1px 18px -2px;
}


@media (min-width: 1024px){
  body{
    overflow: hidden;
  }
  .App {
    margin: 0 50px;
  }
  .root_menu{
    transition: transform .7s;
    transform: translate3d(400px, 0, 0);
    z-index: 200;
  }
  .root_menu.menu_active{
    transform: translate3d(-100px, 0, 0);
  }
  .main{
    box-shadow: 0 0 0 0;
  }
  .main.menu_active{
    transform: scale(1) translate3d(-500px, 0, 0);
    box-shadow: 0 0 0 0;
    border-radius: 0px;
  }
  .main::before{
  content: '';
  transition: width .5s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0vw;
  background: #20232abd;
  z-index: 300;
  }
  .main.menu_active::before{
  width: 100vw;
  }

}
.slider-img{
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
  transition: transform 1.5s ease-in-out;
  z-index: 101;
}
.tab_1 .slider-img{
  transform: translate3d(0, -22vh, 0);
}
.tab_2 .slider-img {
  transform: translate3d(0, -15vh, 0);
}
.phone_block{
  position: absolute;
  z-index: 101;
  transform-origin: center center;
  height: 57vh;
  width: 26.3vh;
  border-radius: 2.5vh;
  /* -webkit-user-select: none;
  user-select: none;
  pointer-events: none; */
  margin-top: 0vh;
  /* overflow-y: auto; */
  display: block;
  color: white;
  transform: translate3d(600px, 300px, 0) rotate(90deg);
  transition: 1.5s ease-in-out;
  /* background: url(images/Ad.svg)center; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.slider-text {
  margin: 0 60px;
  z-index: 101;
  transform-origin: center center;
  height: 57vh;
  /* width: 30.3vh; */

  /* width: 38vh; */
  width: 350px;
  border-radius: 2.5vh;
  /* -webkit-user-select: none;
  user-select: none;
  pointer-events: none; */
  margin-top: 0vh;
  /* overflow-y: auto; */
  display: block;
  color: white;
  /* background: url(images/Ad.svg)center; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.slider-text div {
  display: none;
}
/* .tab_2 
.phone_block {
  height: 57vh;
  width: 26.3vh;
} */
.video_block {
  position: absolute;
  z-index: 101;
  transform-origin: center center;
  height: 57vh;
  width: 26.3vh;
  border-radius: 2.5vh;
  /* -webkit-user-select: none;
  user-select: none;
  pointer-events: none; */
  margin-top: 0vh;
  overflow-y: auto;
  display: block;
  color: white;
  /* transform: translate(600px, 300px) rotate(90deg); */
  transition: 1.5s ease-in-out;
  /* background: url(images/Ad.svg)center; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
div.phone_block{
  user-select: none;
  /* pointer-events: none; */
}
.start .phone_block{
  transform: translate(0px, 7vh) rotate(0deg);
}
.tab_2 .phone_block.video_content {
  transform: translate(-600px, 300px) rotate(-90deg);
  pointer-events: none;
  opacity: 0;
}
.phone_block.how_it_work_content {
  transform: translate(600px, 300px) rotate(90deg);
  opacity: 0;
}
.phone_block.how_it_work_content {
  height: 70.15vh;
  width: 32.37vh;
}
.tab_2 .phone_block.how_it_work_content {
  transform: translate(0px, 7vh) rotate(0deg);
  opacity: 1;
}
.how_it_work_content_phone_figure{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  border-radius: 3vh;
  position: absolute;
  opacity: 0;
  transition: .15s linear;
  pointer-events: none;
}
.how_it_work_content_phone_figure figcaption{
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: fit-content;
  margin: 0 auto;
  transition: .15s linear;
  background: #FFFFFF;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: block;
  align-items: center;
  color: #000000;
  padding: 13px;
}
.how_it_work_content_phone_figure figcaption strong{
  color:#DA1C5C;
}
.tab_2 .how_it_work_content_phone_figure.active figcaption{
  filter: drop-shadow(-4px 8px 20px rgba(0, 0, 0, 0.25));
  transition-delay: 0.75s;
  opacity: 1;
}
.how_it_work_content_phone_figure.active figcaption::after{
  content: "";
  /* background: url("./images/demo/spot.svg"); */
  /* background: url('data:image/svg+xml;utf8,<svg width="21" height="51" viewBox="0 0 21 51" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="11" y="39" width="1" height="39" transform="rotate(-180 11 39)" fill="white"/><path d="M3 40.5C3 36.3579 6.35787 33 10.5 33C14.6421 33 18 36.3579 18 40.5C18 44.6421 14.6421 48 10.5 48C6.35786 48 3 44.6421 3 40.5Z" stroke="#EB3171" stroke-opacity="0.35" stroke-width="6"/><path d="M10.5001 35.9616C7.99359 35.9616 5.96165 37.9935 5.96165 40.5C5.96165 43.0066 7.99359 45.0385 10.5001 45.0385C13.0066 45.0385 15.0386 43.0066 15.0386 40.5001C15.0386 37.9935 13.0066 35.9616 10.5001 35.9616Z" fill="#EC3271" stroke="white"/></svg>'); */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='51' viewBox='0 0 21 51' fill='none'%3E%3Cpath d='M11 39h1v39H11z' transform='rotate(-180 11 39)' fill='%23fff'/%3E%3Cpath d='M3 40.5C3 36.3579 6.35787 33 10.5 33 14.6421 33 18 36.3579 18 40.5 18 44.6421 14.6421 48 10.5 48 6.35786 48 3 44.6421 3 40.5z' stroke='%23eb3171' stroke-opacity='.35' stroke-width='6'/%3E%3Cpath d='M10.5001 35.9616C7.99359 35.9616 5.96165 37.9935 5.96165 40.5 5.96165 43.0066 7.99359 45.0385 10.5001 45.0385 13.0066 45.0385 15.0386 43.0066 15.0386 40.5001 15.0386 37.9935 13.0066 35.9616 10.5001 35.9616z' fill='%23ec3271' stroke='%23fff'/%3E%3C/svg%3E");
  position: absolute;
  width: 21px;
  height: 51px;
}
.how_it_work_content_phone_figure_img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 3vh;
}
.how_it_work_content_phone_figure_caption{    
  position: absolute;
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  transition: .15s linear;
  filter: drop-shadow(-4px 8px 20px rgba(0, 0, 0, 0.25));
  opacity: 0;
}
.tab_2 .how_it_work_content_phone_figure.active .how_it_work_content_phone_figure_caption{    
  transition-delay: 0.5s;
  opacity: 1;
}
.tab_2 .how_it_work_content_phone_figure.active{
  transition-delay: .2s;
  opacity: 1;
  pointer-events: all;
}


.how_it_work_content_phone_figure.how_01{
  opacity: 1;
}
.how_01 .how_it_work_content_phone_figure_caption{   
  width: 115%;
  left: -19%;
  top: 30%;
}
.how_01 figcaption{
  top: 70%;
  width: 80%;
}
.how_01 figcaption::after{
  top: 0;
  left: 20px;
  transform: rotate(180deg) translateY(50px);
}

.how_02 .how_it_work_content_phone_figure_caption{   
  width: 16%;
  left: 73%;
  top: 1%;
}
.how_02 figcaption{
  top: 20%;
  width: 80%;
  padding: 0px;
  background: whitesmoke;
}
.how_02 figcaption div{
  padding: 12px;
  background: white;
  margin: 0;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.how_02 figcaption ul{
  margin: -10px 0;
  padding: 12px;
  padding-left: 1.2rem;
  border-radius: 6px;  
  list-style: none; /* Remove default bullets */
  transition: 1.5s linear;
  max-height: 0;
  overflow: hidden;
}
.how_02.active  figcaption ul{
  transition-delay: 1.5s;
  max-height: 500px;
}
.how_02 figcaption ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #DA1C5C; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.how_02 figcaption::after{
  top: 0;
  left: 83%;
  /* right: 2.3vh; */
  transform: rotate(180deg) translateY(50px);
}

.how_03 .how_it_work_content_phone_figure_caption{   
  width: 16%;
  left: 73%;
  top: 1%;
}
.how_03 figcaption{
  top: 20%;
  width: 80%;
}
.how_03 figcaption::after{
  top: 0;
  left: 80%;
  transform: rotate(180deg) translateY(50px);
}

.how_04 .how_it_work_content_phone_figure_caption{   
  width: 115%;
  left: 2%;
  top: 45%;
}
.how_04 figcaption{
  top: 70%;
  width: 80%;
}
.how_04 figcaption::after{
  top: 0;
  left: 20px;
  transform: rotate(180deg) translateY(50px);
}

.how_05 .how_it_work_content_phone_figure_caption{   
  width: 115%;
  left: -8%;
  top: 80%;
}
.how_05 figcaption{
  top: 55%;
  width: 80%;
}
.how_05 figcaption::after{
  bottom: 0;
  left: 20px;
  transform: rotate(0deg) translateY(50px);
}

.how_06 .how_it_work_content_phone_figure_caption{   
  width: 100%;
  left: 5%;
  top: 45%;
}
.how_06 figcaption{
  top: 20%;
  width: 80%;
}
.how_06 figcaption::after{
  bottom: 0;
  right: 20px;
  transform: rotate(0deg) translateY(50px);
}

.how_07 .how_it_work_content_phone_figure_caption{   
  width: 90%;
  left: 5%;
  top: 15%;
}
.how_07 figcaption{
  left: 25%;
  top: 45%;
  width: 80%;
}
.how_07 figcaption::after{
  top: 0;
  right: 12px;
  transform: rotate(180deg) translateY(50px);
}
.how_07 figcaption::before{
  content: '';

  position: absolute;
  background: #DA1C5C;
  top: -17vh;
  transform: rotate(0deg) translateY(0px);
  right: 11px;
  width: 10px;
  height: 80px;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 5px 10px -4px black;
  
  /* transition: all 1.5s linear;
  animation: demo_phone_button 1s timing-function delay iteration-count direction fill-mode; */
}
.how_07.active figcaption::before{
  /* transition-delay: 1.5s; */
 

  animation: demo_phone_button 4s linear 0s infinite;
}




.how_08 .how_it_work_content_phone_figure_caption{   
  width: 115%;
  left: -19%;
  top: 30%;
}
.how_08 figcaption{
  top: 70%;
  width: 80%;
}
.how_08 figcaption::after{
  top: 0;
  left: 20px;
  transform: rotate(180deg) translateY(50px);
}
.how_08 button {
  width: 100%;
  height: 46px;
  background: #20232A;
  border: 2px solid #DB1C5D;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 35%), inset 0px 0px 0px 0px #db1c5d;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 16px;
  cursor: pointer;
  transition: box-shadow .5s;
}




@keyframes demo_phone_button { 
 0% { 
  right: 11px;
  width: 10px;
 }
 10% { 
   right: 20px;
   width: 1px;
 }  
 25% { 
   right: 20px;
   width: 1px;
 }  
40% { 
   right: 11px;
   width: 10px;
 }
100% { 
  right: 11px;
  width: 10px;
} 
}








.phone_img{
  /* position: absolute; */
  position: relative;
  z-index: 100;
  transform-origin: center center;
  height: 65vh;
  -webkit-user-select: none;
  user-select: none;
  /* pointer-events: none; */
  transform: translate(600px, 300px) rotate(90deg);
  transition: 1.5s ease-in-out;
}
.start .phone_img{
  transform: translate(0px, 7vh) rotate(0deg);;
}
.tab_2 .phone_img{
  height: 80vh;
}

.phone_block div{
  display: block;
  margin: 1.2vh;
  font-size: 2vh;
  font-weight: 100;
}

.phone_block div:nth-child(3){ 
  padding-right: 40%;
}

.phone_block div h1{
  font-size: 5vh;
  margin: auto;
}
.phone_block div h2{
  font-size: 2vh;
  margin: auto;
}


#svg_logo {
  position: absolute;
  z-index: 90;
  transform-origin: center center;
  transform: translate(0px, -5vh);
  width: 65vh;
  height: 65vh;
}



.image_z {
  -webkit-clip-path: url(#my-clip-path);
  clip-path: url(#my-clip-path);
  position: absolute;
  overflow: hidden;
  transform-origin: center center;
  z-index: 90;
  width: 65vh;
  height: 65vh;
  border-radius: 50%;
  margin: 0;
  transform: translate(-600px, 300px) rotate(-360deg) scale(1) ;
  transition: 1.5s ease-in-out;
}
/* .tab_2 .image_z,
.tab_2 .image_z .before {
  height: 80vh;
  width: 80vh;
} */

.start .image_z{
  transform: translate(0px, 0px) rotate(0deg) scale(1);
}
.rotate_right .image_z {
  animation: clipRotateRight 0.7s linear;
}
.rotate_right .image_z .before {
  animation: clipRotateLeft 0.7s linear;
}
.rotate_left .image_z{
  animation: clipRotateLeft 0.7s linear;
}
.rotate_left .image_z .before{
  animation: clipRotateRight 0.7s linear;
}
.image_z .before {
  transform-origin: center center;
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 65vh;
  height: 65vh;
  /* background: url(./images/logo_background.svg) center; */
  background-position-x: 50%;
  background-position-y: center;
  background-size: cover;
  animation: inherit;
  animation-direction: reverse;
  /* transform: rotate(480deg) ; */
  transition: 1.5s ease-in-out;
  transform: rotate(360deg);
}

.logo:hover .image_z_logo{
  animation: clipRotateRight 0.7s linear;
}
.logo:hover .image_z_logo::before{
  animation: clipRotateLeft 0.7s linear;
}
.image_z_logo{
  width: 77px;
  height: 77px;
  -webkit-clip-path: url(#my-clip-path);
  clip-path: url(#my-clip-path);
  position: relative;
  overflow: hidden;
  transform-origin: center center;
  z-index: -1;
  border-radius: 50%;
  margin: 0;
  margin-left: -10px;
  /* transform: translate(-600px, 300px) rotate(-360deg) scale(1) ; */
  transition: 1.5s ease-in-out;
  transform: translate(0px, 0px) rotate(0deg) scale(1);
}
.image_z_logo:before{
  width: 77px;
  height: 77px;
  transform-origin: center center;
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: url(./images/logo_background.svg) center;
  background-size: cover;
  animation: inherit;
  animation-direction: reverse;
  /* transform: rotate(480deg) ; */
  transition: 1.5s ease-in-out;
  transform: rotate(0deg) ;
}
.logo h1 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 36px;
  line-height: 30px;
  font-weight: 400;
}
.logo h1 strong{
  margin: 0;
  padding: 0;
  color: #961e5e;
  display: block;
  letter-spacing: 2px;
  font-weight: 600;
}
.logo img{
  height: 60px;
  width: 82.73;
}

.start .image_z .before{
  /* transform: rotate(120deg); */
  transform: rotate(0deg);
}

@keyframes clipRotateRight {
  0% {
    transform: rotate(-120deg) scale(1);
  }
  50% {
    transform: rotate(-60deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes clipRotateLeft {
  0% {
    transform: rotate(120deg) scale(1);
  }
  50% {
    transform: rotate(60deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}


.svg_hide{
  position: absolute;
  height: 0;
  width: 0;
}
#button{
  position: absolute;
  top: 0;
}


.logo {
  transition: all 1.5s;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 20px 25px;
  display: flex;
  z-index: 200;
  transform: translateY(0vh);
  cursor: pointer;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
/* .menu_active .logo{ 
  transform: translateX(-22vh);
} */
.tab_1 .logo,
.tab_4 .logo{ 
  transform: translateY(-22vh);
}
.menu{
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 20px 25px;
  display: block;
  z-index: 200;
}
#menu_icon {
  transition: all 150ms linear;
  cursor: pointer;
}
#menu_icon:hover {
  transform: scale(1.1);
}
#menu_icon .path_a,
#menu_icon .path_b,
#menu_icon .path_c{
    fill: white;
}
#menu_icon.menu_active .path_a{
  /* d:path("M 7,5 C 5.7894785,5 4.7862649,6.0119972 4.7862649,7.2687576 v 2.8351874 c 0,1.26423 26.2182871,27.404787 27.4288091,27.404787 h 2.766427 c 1.226282,0 2.213735,-1.004528 2.213735,-2.268758 V 32.404787 C 37.195236,31.148027 10.992709,5 9.7664274,5 Z"); */ 
  fill: #DA1C5C
}
#menu_icon.menu_active .path_b,
#menu_icon.menu_active .path_c{
  /* d:path("m 33.027574,5.5018382 c -1.186123,0 -27.7552531,26.7810768 -27.7552531,27.8377258 v 2.388428 c 0,1.058725 0.958836,1.911255 2.144959,1.911255 h 2.6826351 c 1.188714,0 27.756211,-26.781077 27.756211,-27.8377258 V 7.413093 c 0,-1.0587245 -0.957203,-1.9112548 -2.145917,-1.9112548 Z"); */
    fill: #DA1C5C
}
#menu_icon.menu_active .path_d{
  /* d:path("m 24.28125,20.720355 c 0.535204,0.27518 0.535204,0.969539 0,1.244719 l -3.572507,1.836881 c -0.531087,0.273081 -1.191893,-0.072 -1.191893,-0.622359 v -3.673763 c 0,-0.550392 0.660806,-0.895441 1.191893,-0.622359 z"); */
  fill: #DA1C5C;
}
#menu_icon path
{
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 0.5s;
}







.paginator{
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 28px;
  z-index: 200;
}
.paginator div{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: inherit;
  font-size: 0;
  transition: all 0.5s ease-in-out;
  height: 100%;
  color: white;
  cursor: pointer;
}

.paginator div::after{
  content: "";
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  display: block;
  width: 8px;
  height: 8px;
  background: #db1c5c94;
  border-radius: 50%;
  opacity: 0.5;
  transform-origin: center center;
}
.paginator div:hover::after{
  opacity: 1;
}
.paginator div.active::after{
  opacity: 0;
}
.paginator div.active{
  background: #db1c5c;
  font-size: 21px;
}

.paginator i{
  position: absolute;
  left: 0;
  transition: width 0.3s cubic-bezier(0, -0.1, 0.5, 1.5);
  display: block;
  height: 100%;
  width: 42px;
  background: #db1c5d61;
}

.download_popap {
  bottom: 70px;
  padding: 0 16px;
  z-index: 200;
  background: #2B3036;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
}


.resizer.top {
  /* position: absolute; */
  /* z-index: 140; */
  width: 100%;
  height: 45px;
  /* background: linear-gradient(
0deg
, rgba(0, 0, 0, 0) 40%, #2b3135 50%); */
  display: table;
  box-shadow: 0px 1px 3px -3px inset white, 0px 3px 2px -2px inset #2b3135;
  border-radius: 12px 12px 0 0;
}
.resizer.top::before {
  content: '';
  width: 80px;
  height: 5px;
  background: #da1c5b;
  box-shadow: 0 -1px 1px -1px white inset, 0 1px 2px -1px black inset;
  border-radius: 100px;
  margin: 20px auto;
  cursor: ns-resize;
  display: table;
  backdrop-filter: blur(1.5px);
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}
@media (hover: hover) {
  .resizer.top:hover::before {
    opacity: 1;
  }
}
.resizer.top.active::before {
  opacity: 1;
}



.resizer.top.active::after{
  content: '';
  width: 100%;
  height: 45px;
  background-color: #da1c5b;
  border-radius: 10px;
  animation: pulse 0.5s ease-out;
  transform: scale(0);
  position: absolute;
  margin: 0px auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
 }
 @keyframes pulse {
  to {
   transform: scale(2);
   opacity: 0.1;
  }
 }
 
.possibility_popap{

  transform: translate3d(0vh, 100vh, 0);
  transition: transform 1s ease-in-out;
  will-change: transform;
  position: absolute;
  bottom: 0px;
  /* top: 70px;
  height: auto; */

  height: 90vh;
  /* min-height: 30vh;
  max-height: 90vh; */
  color: white;
  padding-top: 0;
  z-index: 230;
  background: #2b3036b3;
  backdrop-filter: blur(3px);
  /* box-shadow: 0px 8px 20px rgb(0 0 0 / 40%); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding-bottom: 52px; */
  width: 100%;
  overflow: hidden;
} 
.news_popap {

  transform: translate3d(0vh, 100vh, 0);
  transition: transform 1s ease-in-out;
  will-change: transform;
  position: absolute;
  bottom: 0px;
  /* top: 70px;
  height: auto; */

  height: 90vh;
  /* min-height: 30vh;
  max-height: 90vh; */
  color: white;
  padding-top: 0;
  z-index: 230;
  background: #2b3036b3;
  backdrop-filter: blur(3px);
  /* box-shadow: 0px 8px 20px rgb(0 0 0 / 40%); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding-bottom: 52px; */
  width: 100%;
  overflow: overlay;
}
.news_popap_content,
.possibility_popap_content{
  margin: 0;
  /* padding-top: 30px; */
  padding-bottom: 52px;
  width: 100%;
  height: auto;
  overflow-y: overlay;
}
.possibility_popap_content section {
  max-width: 100%;
  margin: 0;
  overflow: hidden;
}

.news_popap_content section {
  max-width: 100%;
  margin: 0;
  overflow: hidden;
display: flex;
flex-direction: column;
align-content: center;
justify-content: center;
align-items: center;
flex-wrap: nowrap;
position: relative;
}
.news_popap_content article,
.possibility_popap_content article{
  max-width: 80%;
  margin: 0 auto;
}
.possibility_popap article p,
.news_popap article p{
  text-align: justify;
}

.popap h2 {
  margin: 11px 0; 
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #FFFFFF;
}
.download_popap button,
.buy_popap button,
.title-box-bottom button{
  width: 251px;
  height: 46px;
  background: #20232A;
  border: 2px solid #DB1C5D;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35), inset 0px 0px 0px 0px #db1c5d;;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 16px;
  cursor: pointer;
  transition: box-shadow .5s;
}
/* .download_popap button::after,
.buy_popap button::after{
content: "";
background: #DB1C5D;
width: 100%;
height: 50px;
max-height: 0;
position: absolute;
transition: max-heght 1s;
bottom: 0;
left: 0;
right: 0;
top: 0;
} */
.download_popap button:hover,
.how_08 button:hover,
.buy_popap button:hover,
.title-box-bottom button:hover{
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35), inset 0px -46px 0px 0px #db1c5d;
}
.demo_popap button{
  width: auto;
  height: 50px;
  background: #00000000;
  border: 0px solid #DB1C5D;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  /* text-transform: uppercase; */
  color: #ffffff80;
  margin-bottom: 16px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}
.demo_popap button svg{
  background: #20232A;
  border: 0px solid #DB1C5D;
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  width: 50px;
}
.demo_popap button.active:hover svg{
  box-shadow: 0px 4px 12px rgb(0 0 0 / 35%) inset;
}
.demo_popap button.active:active svg{
  box-shadow: 0px 4px 12px rgb(0 0 0 / 35%) inset;
  background: #111216;
}
.demo_popap button svg path{
  opacity: 0.25;
  fill: #ffffff;
}
.demo_popap button.active,
.demo_popap button.active svg path{
  opacity: 1;
  fill: #DA1C5C;
  color: #ffffff;
}
.demo_popap button.left_arrow svg {
  padding: 10px 19px 10px 15px;
}
.demo_popap button.right_arrow svg{
  padding: 10px 15px 10px 19px;
  margin-right: 30px;
}
.demo_popap button.left_arrow.active svg path{
}
.demo_popap button.right_arrow.active svg path{
}
.demo_popap {
  bottom: 70px;
  z-index: 200;
  background: #2B3036;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 16px 10px;
  width: fit-content;
}
.demo_popap.popap button {
    width: auto;
}




.buy_popap {
  bottom: 70px;
  padding: 0 16px;
  z-index: 200;
  background: #2B3036;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
}
.popap{  
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform-origin: bottom;
  transform: translate3d(0px, 200vw, 0) rotate(90deg);
  transition: transform 1s ease-in-out;
  /* contain: content; */
  /* , height 0s; */
  /* opacity: 0; */
}
.rotate_left .download_popap,
/* .rotate_left .possibility_popap, */
.rotate_left .demo_popap,
.rotate_left .buy_popap{
  transform: translate3d(0vh, 200vw, 0) rotate(90deg);
  /* transform: translate(0px, 100vw) rotate(90deg);
  opacity: 0; */
}
.rotate_right .download_popap,
/* .rotate_right .possibility_popap, */
.rotate_right .demo_popap,
.rotate_right .buy_popap{
  transform: translate3d(0vh, 200vw, 0) rotate(-90deg);
  /* transform: translate(0px, 100vw) rotate(-90deg);
  opacity: 0; */
}

.rotate_left .possibility_popap,
.rotate_right .possibility_popap,
.rotate_left .news_popap,
.rotate_right .news_popap{
  transform: translate3d(0vh, 100vh, 0);
}

.start.tab_0 .download_popap,
.start.tab_1 .possibility_popap,
.start.tab_2 .demo_popap,
.start.tab_3 .buy_popap,
.start.tab_4 .news_popap
{
  transform: translate3d(0px, 0px, 0) rotate(0deg);
  /* opacity: 1; */
}


.title-fig {
    position: absolute;
    font-size: 155px;
    color: #252930;
    font-weight: 500;
    top: 20px;
    left: 48%;
    line-height: 0.9;
    letter-spacing: 0.5px;
    z-index: -2;
}
@media (max-width: 900px){
  .title-fig {
      position: absolute;
      font-size: 95px;
      color: #252930;
      font-weight: 500;
      top: 14px;
      left: 48%;
      line-height: 0.8;
      letter-spacing: 0.5px;
      z-index: -2;
  }
}


/* 
video { height: 100vh; min-height: 100%; }
video {
  object-fit: fill;
}
video {
  object-fit: fill;
} */
video {
  object-fit: cover;
  opacity: 0.45;
}


.phone_figure{
  position: relative;
  transform-origin: center center;
  height: 80vh;
  width: 42.8vh;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background: url('./images/iPhoneX.png');
  background-position-x: 50%;
  background-position-y: center;
  background-size: cover;
  /* transform: translate(600px, 300px) rotate(90deg);
  transition: 1.5s ease-in-out; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vh auto;
}
.phone_figure img{
  position: absolute;
  z-index: 101;
  transform-origin: center center;
  height: 70vh;
  width: 32.37vh;
  border-radius: 2.5vh;
  margin-top: 0vh;
  overflow-y: auto;
  display: block;
  color: white;
  /* transform: translate(600px, 300px) rotate(
90deg
);
  transition: 1.5s ease-in-out; */
  /* background: url(images/Ad.svg)center; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.phone_figure.left{
  transform: translate3d(-20%, 0, 0);
}
.phone_figure.right{
  transform: translate3d(20%, 0, 0);
}


.news_block{
  transform: translate3d(0, 0, 0);
  width: 90%;
  min-height: 300px;
  /* display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: end;
  align-items: center; */
  overflow: hidden;
  border-radius: 20px;
  /* background: white;
  color: black; */

  background: hsla(0,0%,59.6%,.2);
  color: #fff;

  margin-bottom: 10px;
  text-align: left;
  min-height: 300px;
  transition: min-height 0.7s, width 0.7s, transform 0.7s, margin-bottom 0.7s, background 0.7s;
}
.news_block.active {
  width: 100%;
  overflow: overlay;
  background: #25292ff2;
  backdrop-filter: blur(3px);
}
.news_block .close{
  /* background: url("./images/back.svg"); */
  background: url('data:image/svg+xml;utf8,<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" rx="10" fill="#20232A" fill-opacity="0.5"/><path d="M17.7059 25.0069L30.7425 11.9703C31.1932 11.5195 31.1932 10.7887 30.7425 10.338C30.2917 9.88734 29.561 9.88734 29.1102 10.338L15.2574 24.1908C14.8068 24.6415 14.8068 25.3723 15.2574 25.8231L29.1102 39.6759C29.5688 40.1188 30.2996 40.1061 30.7425 39.6475C31.1745 39.2001 31.1745 38.4909 30.7425 38.0436L17.7059 25.0069Z" fill="#DB1C5D"/></svg>');
  display: block;
  width: 50px;
  height: 50px;
  margin: 12px;
  position: fixed;
  transition: transform 0.7s, filter .2s;
  transform: translate3d(-100px, 0, 0);
}
.news_block.active .close{
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}
.news_block.active .close:hover{ 
  filter: drop-shadow(1px 1px 2px black);
}
.news_block_figure{
  margin: 0;
  width: 100%;
  padding: 0;
  max-height: 35vw;
  overflow: hidden;
  transition: min-height 0.7s;
}
.news_block_figure img{
  width: 100%;
  margin: 0 auto;
}
.news_block_figure figcaption{
  display: none;
}


.news_block_article {
  padding: 0 18px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
line-height: 1.3em;
min-height: 5.2em;
transition: min-height 0.7s, margin 0.7s,;
margin: 0 0; 
}

  .news_block.active article {
    margin: 0 5%;
    overflow: hidden;
    -webkit-line-clamp: 4000;
    line-height: 1.3em;
    }

.news_block_figure {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  min-height: 150px;
  height: 150px;
  transition: min-height 0.7s;
}



.typed-wrap {
  margin: -28px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  display: block;
  height: 84px;
}
.typed-cursor{
  opacity: 1;
}
.typed-cursor.typed-cursor--blink{
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
          animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
  50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}




.image_z_logo_inner{
  display: none;
}




.possibility_popap_content_block_figure,
.possibility_popap_content_block_figure img{
  width: 120%;
  transform: translateX(-14%);
  margin: 0;
  position: relative;
  display: block;
}

.possibility_popap_content_block_figure figcaption{
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0; 
  right: 0;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.possibility_popap_content_block_figure figcaption div{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}
.possibility_popap_content_block_figure.left figcaption div{
  transform: translate( -40%, 10%);
}
.possibility_popap_content_block_figure.right figcaption div{
  transform: translate(40%, -10%);
}
.possibility_popap_content_block_figure h2{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: left;
  color: #FFFFFF;
  border-bottom: 2px solid white;
  margin: 0 0 0.83em 0;
}
.possibility_popap_content_block_figure.left h2{
  border-bottom: 2px solid #DA1C5C;
}
.possibility_popap_content_block_figure p{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  margin: 0;
  color: #FEFFFF;
}


.possibility_popap_content_block_figure.left h2,
.possibility_popap_content_block_figure.left p{
  transform: translateX(-200%);
  opacity: 0;
  transition: all 1s;
}
.possibility_popap_content_block_figure.right h2,
.possibility_popap_content_block_figure.right p{
  transform: translateX(200%);
  opacity: 0;
  transition: all 1s;
}
.start.tab_1 .possibility_popap_content_block_figure.left h2,
.start.tab_1 .possibility_popap_content_block_figure.left p,
.start.tab_1 .possibility_popap_content_block_figure.right h2,
.start.tab_1 .possibility_popap_content_block_figure.right p{
  transform: translateX(0%);
  opacity: 1;
  transition-delay: .5s;
}











.language {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;

  margin: 20px 25px;
  padding: 0;
  color: #fff;
  display: flex;
  z-index: 200;
  transform: translate3d(80px, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: .7s;
  width: 43px;
  height: 43px;
  position: absolute;
  top: 0px;
  right: 86px;
  align-items: center;
  justify-content: center;
}
.menu_active .language{
  transform: translate3d(0px, 0, 0);
  opacity: 1;
  pointer-events: all;
}
.language-current {
  z-index: 13;
  display: flex;
  position: relative;
  text-transform: uppercase;
  align-items: center;
}.language-dropdown {
  position: absolute;
  top: 100%;
  padding: 10px 0;
  left: -25px;
  font-size: 13px;
  box-shadow: none;
  margin-top: 0px;
  display: block!important;
  background: #22272d;
  width: max-content;
  text-transform: uppercase;
  border-top: 4px solid #da1c5b;
  transform: translate3d(0, -40px, 0);
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  transition-delay: .2s;
}

.language-dropdown ul,
.language-dropdown li{
 margin: 0;
}
.language-dropdown button {
  padding: 16px;
  width: 100%;
  text-align: center;
  transition: color .25s linear;
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  background: none;
  border: 0;
  cursor: pointer;
}
.language-dropdown button:hover {
  color: #da1c5b;
}
.language:hover .arrow {
  transform: rotate(
180deg
);
}
.language-current .arrow {
  width: 18px;
  margin-left: 7px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language .arrow {
  position: relative;
  top: -1px;
  transform: rotate(0);
  transition: transform .17s linear;
  transform-origin: center;
}
.language:hover {
  color: #da1c5b;
}.language:hover .language-dropdown {
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  pointer-events: all;
  transition-delay: 0s;
}.language:hover .st0 {
  fill: #da1c5b;
}
.language .st0 {
  transition: color .25s linear;
  fill: #fff;
}



.main_footer {
  display: none;
}

@media (min-width: 1024px){



  .main_footer {
    width: 100%;
    max-width: 1200px;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 200;

}
  .main_footer .slider-arrow-next {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    /* background: url(./images/arrow_white.svg) no-repeat; */
    position: absolute;
    right: 30px;
    /* top: 41%; */
    z-index: 2;
    cursor: pointer;
    border: 0px;
    background: none;
} .main_footer .slider-arrow-next::after {
  content: '';
  display: block;
  width: 40px;
  height: 30px;
  background: url(./images/arrow_white.svg) no-repeat;
  margin: 0 12px;
  transition: .25s linear;
}.slider-arrow-next:focus::after  {
  animation: .8s ease-in-out backwards slider_arrow_after ;
}.slider-arrow-next:active::after  {
  animation: none;
}.slider-arrow-next:hover::after  {
  transform: translate(8px, 0px);
}


@keyframes slider_arrow_after { 
  10% { transform: translate(0px, 0px);} 
  70% { transform: translate(30px, 0px); } 
  90% { transform: translate(-5px, 0px); } 
}
@keyframes slider_arrow_next { 
  0% { transform: translate(0px, 0px); opacity: 1; } 
  99% { transform: translate(100px, 0px);  opacity: 0;} 
  100% { transform: translate(-100px, 0px); opacity: 0;} 
}
@keyframes slider_arrow_prev { 
  0% { transform: translate(-100px, 0px); opacity: 0; } 
  100% { transform: translate(0px, 0px); opacity: 1;} 
}
.main_footer .slider-arrow-next div{
  /* transition: .5s ease-in-out; */
  animation: 1s ease-in-out 0s slider_arrow_next;
  animation-fill-mode: forwards;
  opacity: 0;
  position: absolute;
  right: 64px;
  width: max-content;
}
.main_footer .slider-arrow-next div.active{
  /* transition-delay: .5s; */
  /* transform: translate(0px, 0px); */
  opacity: 1;

  animation: .5s ease-in-out 0s slider_arrow_prev;
  animation-fill-mode: forwards;
}


.possibility_popap_content_block_figure,
.possibility_popap_content_block_figure img{
  width: 100%;
  transform: translateX(0%);
}
.possibility_popap_content_block_figure h2{
  font-size: 20px;
  line-height: 30px;
}
.possibility_popap_content_block_figure p{
  font-size: 18px;
  line-height: 130%;
}

  .news_block {
    background: #98989833;
    color: white;
  }


  .logo_inner{
    position: relative;
    height: 77px;
      }.logo_inner div{
        position: absolute;
          }
.image_z_logo_inner{
  display: block;
  clip-path: polygon(91% 50%, 25% 10%, 25% 90%);

  width: 77px;
  height: 77px;
  position: relative;
  overflow: hidden;
  transform-origin: center center;
  z-index: -1;
  border-radius: 50%;
  margin: 0;
  margin-left: -10px;
 
  transition: 1.5s ease-in-out;
  transform: translate(0px, 0px) rotate(
0deg
) scale(1);
}
.image_z_logo.image_z_logo_inner::before{
  width: 55px;
  height: 55px;
  transform-origin: center center;
  content: "";
  position: absolute;
  top: 11px;
  bottom: 11px;
  left: 11px;
  right: 11px;
  background: url(./images/minet.gif) center;
  background-size: cover;
  animation: inherit;
  animation-direction: reverse;
  /* transform: rotate( 
480deg    300  x  170
130
65
); */
  transition: 1.5s ease-in-out, opacity .1s;
  transform: rotate( 
0deg
);
  border-radius: 50%;
  opacity: 0;
}

.logo:hover .image_z_logo.image_z_logo_inner::before{
  opacity: 1;
}
  .logo, .menu{
    margin: 40px 20px;
    padding: 10px;
  }

.logo{
  margin: 34px 20px;
}
  .slider-img {
    transform: translate3d(0, -7vh, 0);
  }
  .tab_0 .slider-img {
    transform: translate3d(20px, -7vh, 0);
}

  .phone_img {
    /* height: 75vh; */
    width: 319px;
    height: 596px;
  }
  .image_z {
    width: 600px;
    height: 600px;
  }
  .image_z .before {
    width: 600px;
    height: 600px;
  }

  .phone_block, .video_block {
    width: 242px;
    height: 522px;
    border-radius: 25px;
  }
  
  .phone_block.video_content div {
    display: none;
  }  





.slider-text div{
  display: block;
  /* margin: 1.2vh; */
  margin: 1.2vh 0;
  font-size: 2vh;
  font-weight: 100;
}
.slider-text .title-box-bottom {
  /* padding-left: 39px; */
  position: relative;
}
.slider-text .title-box-bottom:after {
  content: "";
  width: 152px;
  z-index: -1;
  height: 140px;
  /* background: #2b3036; */
  left: -78px;
  top: -10px;
  position: absolute;
}.title-box-bottom.popap {
  left: -50px;
  transform: translate3d(0px, 0vw, 0) rotate(0deg);
  bottom: -50px;
  padding: 0 50px 10px;
  z-index: 200;
  background: #2B3036;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 40%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
}
  .slider-text div h1 {
    font-size: 62px;
  }.slider-text  div h2 {
    font-size: 24px;
  }
  .tab_2 .phone_img {
    /* height: 85vh; */
    width: 319px;
    height: 596px;
  }.phone_block.how_it_work_content {
    /* height: 74.4vh;
    width: 34.4vh; */
    width: 242px;
    height: 522px;
    border-radius: 25px;
  }
  .download_popap {
    padding: 0 50px 10px;
  }
  /* .start.tab_0 .download_popap,  */
  .start.tab_2 .demo_popap, .start.tab_3 .buy_popap{
    transform: translate3d(45vh, -10vh, 0) rotate(0deg);
  }
  .start.tab_0 .download_popap{
    transform: translate3d(0vh, 200vw, 0) rotate(-90deg);
  }
  .possibility_popap, .news_popap{
      width: 50%;
      right: 50%;
      margin-bottom: 48px;
      max-width: 800px;
  }
  .possibility_popap_content_block{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row; 
    margin: -50px 0 0;
  }
  .phone_figure{
    margin: -25px -5px 0;
  }.phone_figure.left,
  .phone_figure.right{
    transform: translate3d(0%, 0, 0);
  }
  .possibility_popap_content_block:nth-child(even){
    flex-direction: row-reverse; 
  }
  .possibility_popap_content_block:nth-child(3){
    margin: 0 0 0;
  }
  .possibility_popap_content_block article {
    max-width: 45%;
    transform: translate3d(-4%,0,0);
  }
  .possibility_popap_content_block:nth-child(even) article{
    transform: translate3d(4%, 0, 0);
  }
  .tab_1 .slider-img,
  .tab_4 .slider-img {
    transform: translate3d(70%, 0, 0);
  }
  .paginator {
    width: 50%;
    max-width: 500px;
    /* left: 0; */
    transform: translate3d(-50%, 0px, 0px);
  }
  .paginator::after{
    content: "";
    background: #db1c5c;
    display: block;
    height: 100%;
    width: 101%;
    position: absolute;
    transform: translate3d(-99%, 0px, 0px);
  }
  /* .paginator::before{
    content: "";
    animation: animatedBackground 2s linear infinite;
    background: url('./images/paginator_spot.svg');
    background-size: contain;
    opacity: .5;
    display: block;
    height: 100%;
    width: 200%;
    position: absolute;
    transform: translate3d(50%, 0px, 0px);
  } */
  .paginator i {
    background: #db1b5c;
  }.paginator div::after {
    background: #ffffff00;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 8px;
    height: 8px;
  }.paginator div::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #ffffff;
    opacity: 0.15;
    z-index: -1;
  }.paginator div:nth-child(4)::before {
    opacity: 0.1;
  }.paginator div:nth-child(5)::before {
    opacity: 0.08;
  }.paginator div:nth-child(6)::before {
    opacity: 0.05;
  }

  .language {
    margin: 40px 20px;
    padding: 10px;
  }
  .language-dropdown {
    left: -15px;
    margin-top: -4px;
  }
}
@keyframes animatedBackground {
  0% {
      background-position: 0 0;
  }
  100% {
      background-position: -60px 0px;/* анимируем свойство background-position */
  }
}


@media (max-width: 900px)
{

}

select{
  position: absolute;
  z-index: 900;
} 